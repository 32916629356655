import React from "react";
import classNames from "classnames";

export type Props = {
  data: {
    src: string;
    text?: string;
    icon?: string;
    additionalClassNames: Array<String>;
    iconClasses?: Array<String>;
    textClasses?: Array<String>;
    onlyImage?: boolean;
  }
}

const Link = ({ data }: Props) => {
  const {
    src,
    additionalClassNames,
    text,
    icon,
    iconClasses,
    textClasses,
    onlyImage = false
  } = data;

  if (!src) {
    return null;
  }

  if(src.toLowerCase() === "unknown") {
    return <span> {src} </span>
  }

  return (
    <a href={src} className={classNames(additionalClassNames)} target="_blank" >
      <div className={'link-wrapper'}>
        <span className={classNames(iconClasses)}>
          {icon && <img src={icon} alt="link icon" />}
        </span>
        {!onlyImage && <span className={classNames(textClasses)}> {text || src} </span>}
      </div>
    </a>
  );
};

export default Link;
