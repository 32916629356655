import React from "react";
import classNames from "classnames";

export type Props = {
  data: {
    title: string;
    additionalClassNames: Array<String>;
  }
};

const TraceabilityHeader = ({ data }: Props) => {
  const { title, additionalClassNames } = data;
  return <header className={classNames(additionalClassNames)}>{title}</header>;
};

export default TraceabilityHeader;
