import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export type Props = {
  data: {
    text: string;
    action: string;
    additionalClassNames?: Array<String>;
    image: string;
    imageClasses?: Array<String>;
    dynamicColor?: string;
    src?: string;
  }
};

const NavBar = ({ data }: Props) => {
  let navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  const {
    text,
    src,
    action,
    additionalClassNames,
    image,
    imageClasses,
    dynamicColor
  } = data;

  return (
    <div
      className={`spa-navbar ${classNames(additionalClassNames)}`}
      style={dynamicColor && {backgroundColor: dynamicColor}}
    >
      {action === "back" && (
        <span
          className={`spa-navbar-text spa-navbar-back`}
          onClick={back}
        >
          {image ? (
            <img
              src={image}
              className={`${classNames(imageClasses)}`}
              alt="back"
            />
          ) : (
            <span style={{ fontSize: '12px' }}> Back </span>
          )}
        </span>
      )}
      {text && <span className={`spa-navbar-text`}>{text}</span>}
      {src && <img className={`spa-navbar-image`} src={src} alt="navigation"/>}
    </div>
  );
};

export default NavBar;
