import React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import Builder from "./Builder";

const CustomRoutes = ({ data, customProps, ...rest }) => {
  const { children } = data;
  const { business, ean, product, lot } = useParams();

  return (
    <Routes>
      {children?.map((child, index) => {
        return child.name === 'RouteContainer' ? (
          <Route
            key={`${index}_${Math.random()}`}
            path={child.data?.path}
            element={child.data?.children?.map((child, index) => (
              <Builder
                key={`${index}_${Math.random()}`}
                schema={child}
                customProps={{business, ean, product, lot}}
                {...rest}
              />
            ))}
          />
        ) : (
          <Builder
            key={`${index}_${Math.random()}`}
            schema={child}
            customProps={{business, ean, product, lot}}
            {...rest}
          />
        )
      })}
    </Routes>
  )
};

export default CustomRoutes;
