import React from "react";

const IndicatorFooterLogo = () => (
  <svg
    version="1.1"
    id="marianne_seule"
    focusable="false"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 55.06 20.02"
    // style={{ enableBackground: "new 0 0 55.06 20.02" }}
    // xml:space="preserve"
  >
    <g>
      <path
        fill="#000091"
        d="M22.76,17.66c0.21-0.21,0.43-0.45,0.65-0.68h0c0.4-0.45,0.8-0.89,1.26-1.29c0.14-0.13,0.28-0.24,0.41-0.33
		c0.04-0.04,0.04-0.11,0.08-0.15c-0.19,0.08-0.3,0.23-0.49,0.3c-0.04,0-0.08-0.04-0.04-0.08c0.13-0.1,0.27-0.2,0.4-0.3
		c-0.01,0-0.02,0-0.03,0c-0.04,0-0.04-0.04-0.04-0.08c-0.49-0.08-0.87,0.26-1.21,0.57c-0.08,0.04-0.15-0.04-0.19-0.04
		c-0.57,0.19-0.98,0.68-1.55,0.91c0-0.04,0-0.04,0-0.08c-0.23,0.08-0.45,0.21-0.68,0.26c-0.34,0.08-0.64,0.04-0.95,0.04
		c-0.46,0.05-0.92,0.15-1.38,0.24c-0.01,0-0.03,0.01-0.04,0.01c-0.25,0.07-0.48,0.16-0.7,0.28c-0.01,0-0.02,0.01-0.03,0.01
		c-0.02,0.02-0.04,0.05-0.05,0.07c-0.08,0.09-0.15,0.17-0.26,0.23c-0.23,0.12-0.42,0.32-0.63,0.49c-0.01,0.01-0.04,0.02-0.06,0.02
		c-0.2,0.19-0.4,0.39-0.61,0.58c-0.01,0.01-0.07,0.02-0.12,0.01c0,0,0,0,0,0c0.01-0.01,0.01-0.02,0.02-0.04
		c0.03-0.05,0.06-0.11,0.1-0.16c0.04-0.06,0.07-0.12,0.11-0.18c0.05-0.08,0.1-0.16,0.16-0.23c0.01-0.02,0.01-0.04,0-0.05
		c-0.01-0.01-0.03-0.02-0.05-0.02c0.19-0.18,0.41-0.34,0.63-0.47c0,0,0,0,0-0.01c-0.03,0.01-0.06-0.01-0.04-0.04
		c0.02-0.03,0.04-0.07,0.07-0.1c0-0.01,0.01-0.02,0.01-0.03c-0.01-0.01-0.02-0.02-0.03-0.03c-0.06,0.04-0.13,0.08-0.18,0.12
		c-0.09,0.08-0.16,0.25-0.3,0.25c-0.01,0-0.04,0-0.06-0.01c-0.02,0-0.03-0.01-0.04-0.01c0,0,0-0.01,0-0.01c0,0,0-0.01,0.01-0.01
		c0,0,0-0.01,0.01-0.01c0.01-0.01,0.01-0.02,0.02-0.03c0.01-0.01,0.01-0.02,0.02-0.03c0-0.01,0.01-0.02,0.01-0.03
		c0.01-0.02,0.02-0.03,0.03-0.05c0.01-0.01,0.01-0.02,0.02-0.03c0.02-0.03,0.03-0.06,0.05-0.08c0.01-0.01,0.02-0.03,0.02-0.04
		c0.01-0.02,0.03-0.05,0.04-0.07c0.02-0.03,0-0.06-0.03-0.07c0.07-0.09,0.16-0.17,0.26-0.23h-0.01c0.14-0.07,0.29-0.16,0.43-0.24
		c0.02-0.02,0.04-0.04,0.06-0.05c-0.22,0.07-0.41,0.17-0.6,0.3c0,0-0.05,0.02-0.06,0.03c0,0-0.04,0.02-0.09-0.03
		c-0.01-0.01-0.01-0.02-0.01-0.03c0.04-0.08,0.15-0.11,0.23-0.19c0.04,0,0.08,0,0.08,0.04c1.21-0.95,2.88-0.72,4.28-1.21
		c0.11-0.08,0.23-0.15,0.34-0.23c0.19-0.08,0.34-0.26,0.57-0.38c0.3-0.23,0.53-0.49,0.64-0.87c0-0.04-0.04-0.08-0.04-0.08
		c-0.49,0.53-1.06,0.95-1.66,1.25c-0.79,0.42-1.66,0.34-2.5,0.45c0.04-0.08,0.11-0.08,0.19-0.08c0-0.11,0.08-0.15,0.15-0.23
		c0.04,0,0.08,0,0.11,0c0.04,0,0.04-0.08,0.08-0.08c0.08,0,0.19-0.04,0.15-0.04c-0.11-0.15-0.34,0.11-0.53,0
		c0.08-0.08,0.04-0.19,0.11-0.23c0.04,0,0.11,0,0.15,0c0-0.08,0.08-0.15,0.08-0.15c0.57-0.34,1.1-0.61,1.63-0.91
		c-0.11,0-0.19,0.11-0.3,0.04c0.08,0,0-0.11,0.08-0.11c0.42-0.11,0.76-0.34,1.17-0.49c-0.15,0-0.26,0.11-0.42,0
		c0.08-0.04,0.11-0.11,0.23-0.11c0-0.04,0-0.08,0-0.11c0-0.04,0.04-0.04,0.08-0.04c-0.04,0-0.08-0.04-0.08-0.04
		c0.04-0.08,0.15-0.04,0.23-0.11c-0.04,0-0.11,0-0.11-0.04c0.11-0.15,0.3-0.19,0.49-0.23c-0.04-0.08-0.15,0-0.15-0.08
		c0-0.04,0.04-0.04,0.08-0.04c-0.04,0-0.04,0-0.08,0c-0.08-0.04-0.04-0.11-0.04-0.15c0.23-0.26,0.23-0.61,0.34-0.91
		c-0.04,0-0.08,0-0.08-0.04C22.2,12,21.6,12.15,21.03,12.3c-0.04,0-0.15,0-0.19,0c-0.19,0.08-0.45,0.08-0.64-0.04
		c-0.15-0.08-0.23-0.19-0.38-0.3c-0.3-0.19-0.61-0.34-0.95-0.45c-0.95-0.3-1.93-0.45-2.91-0.42c0.42-0.23,0.88-0.25,1.32-0.38
		c0.64-0.19,1.25-0.42,1.93-0.38c-0.11-0.04-0.26,0-0.38,0c-0.53-0.04-1.06,0.11-1.63,0.23c-0.38,0.08-0.72,0.23-1.1,0.3
		c-0.23,0.08-0.34,0.3-0.61,0.26c0,0,0-0.08,0-0.11c0.38-0.45,0.83-0.91,1.44-0.95c0.68-0.11,1.32,0,2.01,0.08
		c0.49,0.04,0.95,0.15,1.44,0.26c0.19,0,0.23,0.3,0.38,0.34c0.23,0.08,0.45,0,0.68,0.15c0-0.08-0.04-0.15,0-0.23
		c0.15-0.15,0.34,0.04,0.49-0.04c0.3-0.19-0.26-0.53-0.42-0.79c0-0.04,0.04-0.08,0.04-0.08c0.3,0.26,0.53,0.57,0.91,0.76
		c0.19,0.08,0.64,0.19,0.57-0.04c-0.19-0.42-0.57-0.76-0.87-1.13c0-0.04,0-0.11,0-0.15c-0.08,0-0.08-0.04-0.11-0.08
		c0-0.04,0-0.11,0-0.15c-0.15-0.08-0.11-0.23-0.19-0.34c-0.11-0.19-0.04-0.45-0.11-0.68c-0.08-0.23-0.11-0.42-0.15-0.64
		c-0.11-0.64-0.26-1.21-0.34-1.82c-0.08-0.72,0.42-1.29,0.76-1.93c0.26-0.45,0.57-0.91,1.06-1.21c0.11-0.45,0.42-0.83,0.72-1.21
		c0.3-0.37,0.8-0.59,1.17-0.76C25.49,0.15,25.96,0,25.96,0H0v20.02h18.39c0.72-0.52,1.44-0.76,2.45-1.26
		C21.31,18.53,22.39,18.03,22.76,17.66z M16.94,14.95c-0.08,0-0.23,0.04-0.19-0.04c0.04-0.19,0.3-0.19,0.45-0.26
		c0.08-0.04,0.19-0.11,0.26-0.08c0.08,0.11,0.19,0.08,0.26,0.15C17.51,14.95,17.21,14.84,16.94,14.95z M11.15,14.12
		c0,0-0.04-0.04-0.04-0.08c0.49-0.64,0.87-1.25,1.21-1.93c0.49-0.26,0.9-0.65,1.29-1.06c0.64-0.68,1.32-1.29,2.12-1.66
		c0.3-0.11,0.68-0.08,0.98,0.04c-0.11,0.15-0.3,0.11-0.45,0.23c-0.04,0-0.08,0-0.11-0.04c0.04-0.04,0.04-0.08,0.04-0.11
		c-0.38,0.42-0.91,0.61-1.21,1.1c-0.23,0.38-0.38,0.87-0.87,0.98c-0.15,0.04,0.04-0.11-0.04-0.08
		C12.89,12.23,12.06,13.1,11.15,14.12z M14.29,11.62c-0.04,0.08-0.08,0.08-0.11,0.15c-0.04,0.08-0.08,0.11-0.15,0.15
		c-0.04,0-0.08,0-0.08-0.04c0.04-0.15,0.15-0.3,0.3-0.34C14.29,11.54,14.29,11.58,14.29,11.62z M16.05,17.29
		c-0.02,0.04-0.06,0.08-0.09,0.11c0.05,0.01,0.08,0.03,0.05,0.06c-0.09,0.08-0.17,0.16-0.29,0.2c-0.01,0-0.03,0.01-0.06,0.01
		c-0.05,0.04-0.09,0.09-0.14,0.13c-0.04,0.04-0.25,0.01-0.19-0.05c0.1-0.09,0.18-0.18,0.28-0.26c0.05-0.05,0.11-0.1,0.16-0.15
		c0.03-0.03,0.05-0.06,0.08-0.08C15.88,17.23,16.08,17.21,16.05,17.29z M15.37,16.98C15.37,16.98,15.37,16.98,15.37,16.98
		c-0.15,0.11-0.29,0.21-0.44,0.29c-0.16,0.09-0.33,0.17-0.5,0.25c0,0,0-0.01,0-0.01c-0.01-0.02-0.04-0.03-0.06-0.02
		c-0.14,0.08-0.27,0.18-0.38,0.29c-0.02,0.02-0.04,0.03-0.05,0.05c0,0,0,0,0,0c0,0,0,0,0,0c-0.02,0.02-0.04,0.04-0.05,0.05
		c0,0,0,0-0.01,0.01c0,0,0,0,0,0c-0.03,0.03-0.05,0.05-0.08,0.09c-0.02,0.02-0.03,0.04-0.05,0.05c-0.02,0.02-0.09,0.02-0.08-0.03
		c0,0,0,0,0-0.01c-0.02,0.01-0.04,0.02-0.07,0.03c-0.02,0.01-0.04,0.02-0.06,0.03c-0.01,0-0.02,0-0.03,0c-0.01,0-0.02,0-0.03,0.01
		c-0.05,0.04-0.09,0.07-0.14,0.12c-0.08,0.07-0.15,0.15-0.22,0.23c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0-0.01,0.01-0.01,0.01
		c0,0.01-0.01,0.01-0.01,0.02c0,0,0,0,0,0c-0.01,0.01-0.02,0.03-0.03,0.04c0,0,0,0,0,0c-0.01,0.01-0.02,0.01-0.03,0.01
		c-0.01-0.01-0.01-0.01-0.02-0.02c0-0.01-0.01-0.01-0.01-0.02c-0.01-0.02-0.02-0.04-0.03-0.06c0,0,0,0,0,0
		c0-0.01-0.01-0.01-0.01-0.02c0.04-0.04,0.08-0.09,0.12-0.14c0,0,0.01-0.01,0.01-0.01c0.01-0.02,0.03-0.03,0.04-0.05
		c0.02-0.03,0.04-0.05,0.06-0.08c0.01-0.01,0.02-0.02,0.02-0.03c0.04-0.05,0.08-0.11,0.12-0.16c0,0,0,0,0,0
		c0.01-0.01,0.01-0.02,0.02-0.02c0.02-0.03,0.04-0.05,0.05-0.08c0.01-0.02,0.03-0.05,0.04-0.08c0,0,0,0,0-0.01c0,0,0-0.01,0-0.01
		c0,0,0-0.01,0.01-0.01c0.01-0.03,0.03-0.06,0.04-0.09c0,0,0-0.01,0-0.01c0-0.01,0.01-0.02,0.01-0.02c0-0.01,0.01-0.03,0.01-0.04
		l0,0c0,0,0-0.01,0-0.01c0-0.02,0.01-0.04,0.01-0.06c0-0.01,0-0.02,0-0.03c0.04-0.07,0.09-0.14,0.15-0.2
		c-0.01,0-0.01,0.01-0.02,0.01c-0.05,0.03-0.09,0.08-0.14,0.12c-0.04,0.03-0.12-0.02-0.07-0.06c0.03-0.02,0.06-0.05,0.09-0.08
		c0,0,0,0,0-0.01c0.06-0.06,0.12-0.14,0.19-0.19c0.04-0.03,0.08-0.06,0.12-0.09c0.01,0,0.01-0.01,0.02-0.02
		c0.03-0.03,0.05-0.07,0.08-0.1c0,0,0,0,0,0c0.36-0.35,0.97-0.33,1.45-0.56c0.19-0.08,0.42,0.04,0.61,0c0.11,0,0.23,0,0.34,0.08
		C16,16.57,15.68,16.78,15.37,16.98z M16.15,14.34c-0.04-0.04,0.11,0,0.15-0.08c-0.11,0-0.19,0-0.3,0c-0.04,0-0.04-0.04-0.04-0.08
		c-0.19,0.04-0.42,0.11-0.61,0.15c-0.26,0.08-0.49,0.26-0.79,0.34c-0.42,0.15-0.76,0.49-1.21,0.64c-0.04,0-0.04-0.04-0.04-0.08
		c0.04-0.11,0.19-0.15,0.26-0.26c0-0.04,0-0.08-0.04-0.08c0.3-0.42,0.72-0.64,1.1-0.98c0-0.04,0-0.08,0-0.11
		c0.11-0.15,0.3-0.23,0.38-0.42c0.04-0.11,0.19-0.26,0.38-0.34c-0.04-0.04-0.11-0.04-0.11-0.11c-0.15,0-0.3,0.08-0.45-0.04
		c0.07-0.07,0.15-0.11,0.24-0.13c-0.03-0.01-0.06-0.02-0.09-0.05c-0.04-0.08,0.07-0.16,0.19-0.19c0.15-0.04,0.34-0.04,0.45-0.15
		c-0.26-0.04-0.57,0.08-0.83-0.08c0.19-0.49,0.49-0.91,0.95-1.13c0.04,0,0.11,0,0.11,0.04c0,0.19-0.11,0.34-0.3,0.38
		c0.3,0.08,0.61,0.08,0.91,0.23c-0.04,0.08-0.11,0.04-0.15,0.04c0.19,0.11,0.42,0.04,0.61,0.19c-0.11,0.11-0.23,0-0.34,0
		c1.17,0.34,2.42,0.61,3.4,1.36c-0.83,0.42-1.7,0.61-2.61,0.79c-0.11,0-0.19,0-0.3-0.04c0,0.04,0,0.11-0.04,0.11
		c-0.15,0-0.26,0-0.38,0.08C16.49,14.42,16.26,14.46,16.15,14.34z"
      ></path>
      <g>
        <path
          fill="#E1000F"
          d="M55.06,0H33.52c0,0,0.04,0.01,0.2,0.09c0.17,0.09,0.4,0.21,0.54,0.29c0.28,0.15,0.55,0.33,0.72,0.61
			c0.08,0.11,0.19,0.34,0.11,0.49c-0.08,0.19-0.11,0.49-0.3,0.57c-0.23,0.11-0.53,0.11-0.79,0.08c-0.15,0-0.3-0.04-0.45-0.08
			c0.57,0.23,1.1,0.49,1.48,1.02c0.04,0.08,0.19,0.11,0.34,0.11c0.04,0,0.04,0.08,0.04,0.11c-0.08,0.08-0.15,0.11-0.11,0.23
			c0.04,0,0.08,0,0.11,0c0.19-0.08,0.15-0.45,0.42-0.34c0.19,0.11,0.26,0.38,0.15,0.57c-0.15,0.15-0.3,0.26-0.45,0.38
			c-0.04,0.08-0.04,0.19,0,0.26c0.11,0.15,0.15,0.3,0.19,0.45c0.11,0.26,0.15,0.57,0.26,0.83c0.15,0.57,0.3,1.13,0.26,1.7
			c0,0.3-0.15,0.57-0.04,0.87c0.08,0.3,0.26,0.53,0.42,0.79c0.15,0.23,0.3,0.38,0.42,0.61c0.23,0.38,0.64,0.76,0.45,1.21
			c-0.11,0.26-0.53,0.23-0.79,0.38c-0.23,0.19-0.04,0.49,0.08,0.68c0.19,0.34-0.23,0.57-0.49,0.68c0.08,0.11,0.23,0.08,0.26,0.15
			c0.04,0.19,0.23,0.3,0.11,0.49c-0.15,0.23-0.61,0.34-0.38,0.68c0.15,0.26,0.05,0.56-0.04,0.83c-0.11,0.34-0.42,0.49-0.68,0.57
			c-0.23,0.08-0.49,0.08-0.72,0.04c-0.08-0.04-0.15-0.08-0.23-0.08c-0.64-0.08-1.29-0.26-1.93-0.26c-0.19,0.04-0.38,0.08-0.53,0.15
			c-0.17,0.13-0.32,0.26-0.46,0.41c0,0,0,0,0,0c-0.03,0.03-0.06,0.06-0.08,0.09c-0.02,0.02-0.03,0.04-0.05,0.06
			c-0.01,0.01-0.02,0.03-0.03,0.04c-0.11,0.14-0.21,0.28-0.3,0.44c-0.01,0.01-0.01,0.02-0.02,0.03c-0.01,0.02-0.02,0.04-0.03,0.05
			c-0.12,0.23-0.22,0.46-0.29,0.69c-0.26,0.87-0.14,1.61,0.04,1.79c0.05,0.05,1.25,0.42,2.08,0.79c0.39,0.17,0.67,0.31,0.89,0.45
			h21.15V0z"
        ></path>
      </g>
      <path
        fill="#9D9D9C"
        d="M34.8,7.3c0.15,0.04,0.38,0.04,0.38,0.11c-0.08,0.3-0.53,0.38-0.76,0.68h-0.11c-0.11,0.08-0.08,0.26-0.19,0.26
		c-0.11-0.04-0.23,0-0.34,0.04c0.15,0.15,0.34,0.26,0.57,0.23c0.04,0,0.11,0.08,0.11,0.15c0,0,0.04,0,0.08-0.04
		c0.04,0,0.08,0,0.08,0.04v0.15c-0.11,0.15-0.3,0.08-0.45,0.11c0.3,0.08,0.61,0.08,0.87,0c0.23-0.08,0-0.45,0.15-0.64
		c-0.08,0,0-0.11-0.08-0.11c0.08-0.08,0.15-0.19,0.23-0.23c0.08,0,0.19-0.04,0.23-0.11c0-0.08-0.15-0.11-0.11-0.19
		c0.23-0.15,0.42-0.38,0.34-0.61c-0.04-0.11-0.34-0.11-0.53-0.19c-0.19-0.08-0.42,0-0.64,0.04c-0.19,0-0.38,0.11-0.57,0.15
		c-0.26,0.08-0.49,0.23-0.72,0.38c0.26-0.11,0.53-0.15,0.83-0.23C34.38,7.3,34.58,7.25,34.8,7.3z"
      ></path>
    </g>
  </svg>
);

export default IndicatorFooterLogo;
