import React from "react";
import classNames from "classnames";
import Builder from "../../Builder";

export type Props = {
  data: {
    label: string;
    text: string;
    additionalClassNames?: Array<String>;
    textClasses?: Array<String>;
    labelClasses?: Array<String>;
    customInlineStyles?: object;
    isOptional?: {
      test: any;
    },
    isOptionalLabel?: {
      test: any;
    }
  };
};

const SectionSeparatedText = ({ data }: Props) => {
  const {
    additionalClassNames,
    text,
    label,
    textClasses,
    labelClasses,
    customInlineStyles,
    isOptional,
    isOptionalLabel,
  } = data;

  if (isOptional && (!isOptional?.test || isOptional?.test?.length === 0)) {
    return null;
  }

  const textContent =
    Array.isArray(text)
      ? text?.map((child, index) => (
        <Builder
          key={`${index}_${Math.random()}`}
          schema={child}
        />
      ))
      : text;

  return (
    <p
      className={`${classNames(additionalClassNames)}`}
      style={{...(customInlineStyles && {...customInlineStyles})}}
    >
      {isOptionalLabel && (!isOptionalLabel.test || isOptionalLabel.test.length === 0) ?
        '' :
        (<span className={`separated-text-label ${classNames(labelClasses)}`}>{label}</span>)
      }
      <span className={classNames(textClasses)}>{textContent}</span>
    </p>
  );
};

export default SectionSeparatedText;
