import React, { useEffect, useState } from "react";
import Builder, { BuilderComponent } from "../../Builder";
import classNames from "classnames";
import { checkImgExist } from "../../helpers";

export type Props = {
  data: {
    additionalClassNames?: Array<String>;
    image: string;
    imageClasses?: Array<String>;
    content: BuilderComponent[];
    contentClasses?: Array<String>;
    customize?: boolean;
    customSuffix?: string;
    product?: string;
    business?: string;
    isOptional?: {
      test: any;
    }
  }
};

const Card = ({ data }: Props) => {
  const {
    image,
    content,
    additionalClassNames,
    imageClasses,
    contentClasses,
    customize,
    customSuffix,
    product,
    business,
  } = data;
  const [customImage, setCustomImage] = useState(image);
  const productName = product?.toLowerCase().replaceAll(' ', '_');

  useEffect(() => {
    if (customize) {
      const cardUrl = `${process.env.REACT_APP_BUCKET}${business}/v1/non-traced/${productName}_${customSuffix}.jpg`;
      checkImgExist(cardUrl)
        .then((result) => {
          setCustomImage(cardUrl);
        })
        .catch((e) => {
          setCustomImage(image);
        });
    }
  }, []);

  if (data.isOptional && (!data.isOptional?.test || data.isOptional?.test.length === 0)) {
    return null;
  }

  return (
    <div className={`spa-card ${classNames(additionalClassNames)}`}>
      <div
        style={{ backgroundImage: `url("${customize ? customImage : image}")` }}
        className={classNames(imageClasses)}
      />
      <div className={`spa-card-children ${classNames(contentClasses)}`}>
        <Builder
          key={`${Math.random()}`}
          children={content} 
        />
      </div>
    </div>
  );
};

export default Card;
