import React from "react";
import classNames from "classnames";

export type Props = {
  data: {
    text: string;
    additionalClassNames: Array<String>;
    separate?: boolean;
    separator?: string;
    stringClassNames?: Array<String>;
    customInlineStyles?: object;
    isOptional?: {
      test: string;
    }
  };
};

const SectionText = ({ data }: Props) => {
  const {
    text,
    additionalClassNames,
    separate,
    separator,
    stringClassNames,
    customInlineStyles,
    isOptional
  } = data;

  if (isOptional && (!isOptional?.test || isOptional?.test?.length === 0)) {
    return null;
  }

  return separate && separator ? (
    <div className={classNames(additionalClassNames)}>
      {text.split(separator).map((str) => (
          <p
            className={classNames(stringClassNames)}
            style={{...(customInlineStyles && {...customInlineStyles})}}
          > {str} </p>
      ))}
    </div>
  ) : (
    <p
      className={classNames(additionalClassNames)}
      style={{...(customInlineStyles && {...customInlineStyles})}}
    >{text}</p>
  )
};

export default SectionText;
