import React from "react";
import classNames from "classnames";
import ProgressRow from "../ProgressRow";

const EcobalyseImpactIndicator = ({ data }) => {
  const {
    description,
    environmentalCostValue,
    environmentalCostUnit,
    totalProductWeight,
    weightPerBatch,
    impactsTitle,
    footNote,
    aboutEcobalyseLinkTitle,
    ecobalyseExplorerLinkTitle,
    categories,
    additionalClassNames,
  } = data;
  return (
    <div className={`ecobalyse-indicator ${classNames(additionalClassNames)}`}>
      <p className="description">{description}</p>
      <div className="ecobalyse-indicator-container">
        <div className="environmental-costs-card">
          <div className="card-body">
            <p className="cost"><span>{environmentalCostValue}</span> {environmentalCostUnit}</p>
            <p className="total">{totalProductWeight}</p>
          </div>
          <div className="card-footer">{weightPerBatch}</div>
        </div>
        <h3>{impactsTitle}</h3>
        <div className="categories-wrapper">
            {categories?.map((category) =>(
                <div className="category" style={{ borderLeft: `6px solid ${category.color}` }}>
                    <p className="category-name">{category.name}</p>
                    <ProgressRow 
                        data={{
                            percent: category.percentage,
                            rowColor: category.color,
                            trailColor: "#fff",
                            showInfo: true,
                            additionalClassNames: ['category-progress-bar'],
                            percentClasses: ['category-progress-percentage']
                        }}
                    />
                </div>
            ))}
        </div>
      </div>
      <p className="foot-note"> {footNote}</p>
      <div className="links-container">
        <a href="https://ecobalyse.beta.gouv.fr/" target="_blank" className="link about">
          {aboutEcobalyseLinkTitle}
        </a>
        <a
          href="https://ecobalyse.beta.gouv.fr/#/explore/textile"
          target="_blank"
          className="link explorer"
        >
          {ecobalyseExplorerLinkTitle}
        </a>
      </div>
    </div>
  );
};

export default EcobalyseImpactIndicator;
