import React from "react";
import classNames from "classnames";
import SectionText from "../SectionText";

export type Props = {
  data: {
    additionalClassNames: Array<String>;
    image: string;
    imageClasses: Array<String>;
    text: string;
    textClasses: Array<String>;
  }
};

const LocationCard = ({ data }: Props) => {
  const {
    image,
    text,
    textClasses,
    additionalClassNames,
    imageClasses,
  } = data;
  return (
    <div className={`spa-location-card ${classNames(additionalClassNames)}`}>
      <div className={`spa-location-card-children`}>
        <SectionText
          data={{
            text,
            additionalClassNames: textClasses,
          }}
        />
      </div>
      <div className={'spa-location-card-image'}>
        <img src={image} alt="Location icon" className={classNames(imageClasses)} />
      </div>
    </div>
  );
};

export default LocationCard;
