import React from "react";
import IndicatorFooterLogo from "./Logo";

interface Props {
  color: string;
  value: string;
  level: string;
}

const DurabilityBlock = ({ color, value, level }: Props) => {
  const getIcon = (level) => {
    switch (level) {
      case "ideal":
        return (
          <svg
            viewBox="0 0 282 325"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M212.993 13L66.1006 13C54.0288 13 52.5242 30.0068 52.1006 38.8403C49.0001 103.5 75.0001 123.5 91.4985 139L107.243 151.577C108.665 153.215 109.377 154.036 109.886 154.823C112.992 159.625 112.992 166.209 109.886 171.011C109.377 171.797 108.665 172.618 107.243 174.256L88.6006 189.5C65.1006 211 41.1006 239 57.6006 299.5C59.237 305.5 62.7381 312.5 68.1725 312.5H217.101C222.535 312.5 225.96 305.5 227.101 299.5C238.601 239 215.601 211.234 192.101 189.5L173.922 174.256C172.5 172.618 171.788 171.797 171.279 171.011C168.173 166.209 168.173 159.625 171.279 154.823C171.788 154.036 172.5 153.215 173.922 151.577L192.101 136.5C208.5 119.5 233 104 228.101 38.8403C227.094 25.4537 224.601 13 212.993 13Z"
              stroke="white"
              stroke-width="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.9497 15H225.382C225.382 15 234.686 61.5448 226.882 90.3824C219.792 116.581 184.898 145.528 169.404 157.164C166.856 160.803 168.46 164.893 169.404 167.283C170.904 173.354 208.924 195.856 220.384 225.464C232.289 256.22 221.884 312.482 221.884 312.482L64.9457 314C64.9457 314 47.2254 262.719 54.9497 234.064C64.222 199.667 108.428 171.836 109.928 167.283C111.442 165.136 111.843 160.2 108.928 157.164C97.9326 141.986 68.676 122.548 59.4479 87.8528C52.4507 61.5448 54.9497 15 54.9497 15Z"
              fill="white"
            />
            <path
              d="M141.102 146.319C109.5 141 58.4999 98 74.9998 31.0003L205 31.0003C226 91.5003 171.5 143 141.102 146.319Z"
              fill="#148C45"
            />
            <path
              d="M140.766 186C172.357 191.381 223.272 234.482 206.64 301.449L76.6406 301.193C55.7597 240.652 110.361 189.259 140.766 186Z"
              fill="#148C45"
            />
            <path
              d="M93.5 200.5C109.574 184.607 141 177 141 177C141 177 176.188 184.297 193.5 200.5C212.977 218.73 221 261 221 261H68C68 261 74.8659 218.924 93.5 200.5Z"
              fill="white"
            />
          </svg>
        );
      case "normal":
        return (
          <svg
            width="282"
            height="325"
            viewBox="0 0 282 325"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M212.993 13L66.1006 13C54.0288 13 52.5242 30.0068 52.1006 38.8403C49.0001 103.5 75.0001 123.5 91.4985 139L107.243 151.577C108.665 153.215 109.377 154.036 109.886 154.823C112.992 159.625 112.992 166.209 109.886 171.011C109.377 171.797 108.665 172.618 107.243 174.256L88.6006 189.5C65.1006 211 41.1006 239 57.6006 299.5C59.237 305.5 62.7381 312.5 68.1725 312.5H217.101C222.535 312.5 225.96 305.5 227.101 299.5C238.601 239 215.601 211.234 192.101 189.5L173.922 174.256C172.5 172.618 171.788 171.797 171.279 171.011C168.173 166.209 168.173 159.625 171.279 154.823C171.788 154.036 172.5 153.215 173.922 151.577L192.101 136.5C208.5 119.5 233 104 228.101 38.8403C227.094 25.4537 224.601 13 212.993 13Z"
              stroke="white"
              stroke-width="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.9497 14H225.382C225.382 14 234.686 60.7005 226.882 89.6345C219.792 115.921 184.898 144.964 169.404 156.64C166.856 160.291 168.46 164.395 169.404 166.792C170.904 172.883 208.924 195.461 220.384 225.168C232.289 256.027 221.884 312.477 221.884 312.477L64.9457 314C64.9457 314 47.2254 262.547 54.9497 233.797C64.222 199.285 108.428 171.36 109.928 166.792C111.442 164.639 111.843 159.685 108.928 156.64C97.9326 141.411 68.676 121.908 59.4479 87.0964C52.4507 60.7005 54.9497 14 54.9497 14Z"
              fill="white"
            />
            <path
              d="M141.102 146.319C109.5 141 58.4999 98 74.9998 31.0003L205 31.0003C226 91.5003 171.5 143 141.102 146.319Z"
              fill="#FFB727"
            />
            <path
              d="M140.766 186C172.357 191.381 223.272 234.482 206.64 301.449L76.6406 301.193C55.7597 240.652 110.361 189.259 140.766 186Z"
              fill="#FFB727"
            />
            <rect x="65" y="26" width="156" height="33" fill="white" />
            <path
              d="M93.5 197.143C109.574 183.52 141 177 141 177C141 177 176.188 183.255 193.5 197.143C212.977 212.768 221 249 221 249H68C68 249 74.8659 212.935 93.5 197.143Z"
              fill="white"
            />
          </svg>
        );
      case "warning":
        return (
          <svg
            viewBox="0 0 282 325"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M212.993 13L66.1005 13C54.0287 13 52.5241 30.0068 52.1005 38.8403C48.9999 103.5 74.9999 123.5 91.4984 139L107.243 151.577C108.665 153.215 109.376 154.036 109.886 154.823C112.992 159.625 112.992 166.209 109.886 171.011C109.376 171.797 108.665 172.618 107.243 174.256L88.6005 189.5C65.1005 211 41.1005 239 57.6005 299.5C59.2368 305.5 62.738 312.5 68.1724 312.5H217.1C222.535 312.5 225.96 305.5 227.1 299.5C238.6 239 215.6 211.234 192.1 189.5L173.922 174.256C172.5 172.618 171.788 171.797 171.279 171.011C168.173 166.209 168.173 159.625 171.279 154.823C171.788 154.036 172.5 153.215 173.922 151.577L192.1 136.5C208.5 119.5 233 104 228.1 38.8403C227.094 25.4537 224.6 13 212.993 13Z"
              stroke="white"
              stroke-width="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.9497 15H225.382C225.382 15 234.686 61.5448 226.882 90.3824C219.792 116.581 184.898 145.528 169.404 157.164C166.856 160.803 168.46 164.893 169.404 167.283C170.904 173.354 208.924 195.856 220.384 225.464C232.289 256.22 221.884 312.482 221.884 312.482L64.9457 314C64.9457 314 47.2254 262.719 54.9497 234.064C64.222 199.667 108.428 171.836 109.928 167.283C111.442 165.136 111.843 160.2 108.928 157.164C97.9326 141.986 68.676 122.548 59.4479 87.8528C52.4507 61.5448 54.9497 15 54.9497 15Z"
              fill="white"
            />
            <path
              d="M141.102 146.319C109.5 141 58.5 98 75 31.0003L205 31.0003C226 91.5003 171.5 143 141.102 146.319Z"
              fill="#F46030"
            />
            <path
              d="M140.766 186C172.357 191.381 223.272 234.482 206.64 301.449L76.6406 301.193C55.7597 240.652 110.361 189.259 140.766 186Z"
              fill="#F46030"
            />
            <rect x="65" y="26" width="156" height="43" fill="white" />
            <path
              d="M93.5 194.345C109.574 182.615 141 177 141 177C141 177 176.188 182.386 193.5 194.345C212.977 207.801 221 239 221 239H68C68 239 74.8659 207.944 93.5 194.345Z"
              fill="white"
            />
          </svg>
        );
      case "danger":
        return (
          <svg
            viewBox="0 0 282 325"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M212.993 13L66.1005 13C54.0287 13 52.5241 30.0068 52.1005 38.8403C48.9999 103.5 74.9999 123.5 91.4984 139L107.243 151.577C108.665 153.215 109.376 154.036 109.886 154.823C112.992 159.625 112.992 166.209 109.886 171.011C109.376 171.797 108.665 172.618 107.243 174.256L88.6005 189.5C65.1005 211 41.1005 239 57.6005 299.5C59.2368 305.5 62.738 312.5 68.1724 312.5H217.1C222.535 312.5 225.96 305.5 227.1 299.5C238.6 239 215.6 211.234 192.1 189.5L173.922 174.256C172.5 172.618 171.788 171.797 171.279 171.011C168.173 166.209 168.173 159.625 171.279 154.823C171.788 154.036 172.5 153.215 173.922 151.577L192.1 136.5C208.5 119.5 233 104 228.1 38.8403C227.094 25.4537 224.6 13 212.993 13Z"
              stroke="white"
              stroke-width="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.9497 14H225.382C225.382 14 234.686 60.7005 226.882 89.6345C219.792 115.921 184.898 144.964 169.404 156.64C166.856 160.291 168.46 164.395 169.404 166.792C170.904 172.883 208.924 195.461 220.384 225.168C232.289 256.027 221.884 312.477 221.884 312.477L64.9457 314C64.9457 314 47.2254 262.547 54.9497 233.797C64.222 199.285 108.428 171.36 109.928 166.792C111.442 164.639 111.843 159.685 108.928 156.64C97.9326 141.411 68.676 121.908 59.4479 87.0964C52.4507 60.7005 54.9497 14 54.9497 14Z"
              fill="white"
            />
            <path
              d="M141.102 146.319C109.5 141 58.5 98 75 31.0003L205 31.0003C226 91.5003 171.5 143 141.102 146.319Z"
              fill="#D02744"
            />
            <path
              d="M140.766 186C172.357 191.381 223.272 234.482 206.64 301.449L76.6406 301.193C55.7597 240.652 110.361 189.259 140.766 186Z"
              fill="#D02744"
            />
            <rect x="65" y="26" width="156" height="56" fill="white" />
            <path
              d="M93.5 191.548C109.574 181.709 141 177 141 177C141 177 176.188 181.517 193.5 191.548C212.977 202.833 221 229 221 229H68C68 229 74.8659 202.953 93.5 191.548Z"
              fill="white"
            />
          </svg>
        );
      case "critical":
        return (
          <svg
            viewBox="0 0 282 325"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M212.993 13L66.1005 13C54.0287 13 52.5241 30.0068 52.1005 38.8403C48.9999 103.5 74.9999 123.5 91.4984 139L107.243 151.577C108.665 153.215 109.376 154.036 109.886 154.823C112.992 159.625 112.992 166.209 109.886 171.011C109.376 171.797 108.665 172.618 107.243 174.256L88.6005 189.5C65.1005 211 41.1005 239 57.6005 299.5C59.2368 305.5 62.738 312.5 68.1724 312.5H217.1C222.535 312.5 225.96 305.5 227.1 299.5C238.6 239 215.6 211.234 192.1 189.5L173.922 174.256C172.5 172.618 171.788 171.797 171.279 171.011C168.173 166.209 168.173 159.625 171.279 154.823C171.788 154.036 172.5 153.215 173.922 151.577L192.1 136.5C208.5 119.5 233 104 228.1 38.8403C227.094 25.4537 224.6 13 212.993 13Z"
              stroke="white"
              stroke-width="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.9497 14H225.382C225.382 14 234.686 60.7005 226.882 89.6345C219.792 115.921 184.898 144.964 169.404 156.64C166.856 160.291 168.46 164.395 169.404 166.792C170.904 172.883 208.924 195.461 220.384 225.168C232.289 256.027 221.884 312.477 221.884 312.477L64.9457 314C64.9457 314 47.2254 262.547 54.9497 233.797C64.222 199.285 108.428 171.36 109.928 166.792C111.442 164.639 111.843 159.685 108.928 156.64C97.9326 141.411 68.676 121.908 59.4479 87.0964C52.4507 60.7005 54.9497 14 54.9497 14Z"
              fill="white"
            />
            <path
              d="M141.102 146.319C109.5 141 58.5 98 75 31.0003L205 31.0003C226 91.5003 171.5 143 141.102 146.319Z"
              fill="#922A41"
            />
            <path
              d="M140.766 186C172.357 191.381 223.272 234.482 206.64 301.449L76.6406 301.193C55.7597 240.652 110.361 189.259 140.766 186Z"
              fill="#922A41"
            />
            <rect x="65" y="26" width="156" height="72" fill="white" />
            <path
              d="M93.5 188.75C109.574 180.803 141 177 141 177C141 177 176.188 180.649 193.5 188.75C212.977 197.865 221 219 221 219H68C68 219 74.8659 197.962 93.5 188.75Z"
              fill="white"
            />
          </svg>
        );
      default:
        return null;
    }
  };
  return (
    <div className="durability-block-container">
      <p className="durability-block-label">
        Indice de <span>durabilité</span>
      </p>
      <div className="durability-block-info" style={{ backgroundColor: color }}>
        <div className="durability-block-icon">{getIcon(level)}</div>
        <div className="durability-block-content">
          <p><span className="value">{value}</span> <span className="total">/ 10</span></p>
        </div>
      </div>
      <div className="durability-block-footer">
        <div className="durability-block-footer-text">
            <p>Fiabilité</p>
            <div className="row" style={{ backgroundColor: color }}></div>
            <p>Réparabilité</p>
        </div>
        <div className="durability-block-footer-label-row">
            <div className="durability-block-footer-icon"><IndicatorFooterLogo /></div>
            <p className="durability-block-footer-label">RÉPUBLIQUE FRANÇAISE</p>
        </div>
      </div>
    </div>
  );
};

export default DurabilityBlock;
