import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const Map = ({ data }) => {
  const {
    markers,
    center,
    zoom,
    legend,
    className,
    iconSize,
    iconAnchor
  } = data;

  if (!data) {
    return null;
  }

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={false}
      style={{ height: "100vw" }}
      className={className}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {Array.isArray(markers) &&
        markers.map((marker) => {
          const {
            position,
            icon,
            popup,
          } = marker;

          return (
            <Marker
              position={position}
              key={position.toString()}
              icon={
                new L.icon({
                  iconUrl: legend?.[icon]?.["icon"],
                  iconSize: iconSize || [30, 30],
                  iconAnchor: iconAnchor || null,
                  className: 'spa-marker-icon'
                })
              }
            >
              {popup && (
                <Popup>
                  <div className={'spa-map-popup-container'}>
                    <div className={'spa-map-popup-image-wrapper'}>
                      {popup?.popupImage && (<img src={popup?.popupImage} alt='Popup Image' />)}
                    </div>
                    <p>{popup?.popupText}</p>
                  </div>
                </Popup>
              )}
            </Marker>
          )
        })}
    </MapContainer>
  );
};

export default Map;
