import React from "react";

interface Props {
  content: string;
  type: "bac" | "glass";
}

const IndividualTriman = ({ content, type }: Props) => {
  return (
    <div className="individual-triman">
      <img
        src={
          type === "bac"
            ? "/images/restitution/agec/triman/bar-de-tri.svg"
            : "/images/restitution/agec/triman/tri-verre.svg"
        }
        className={`${type}-image`}
        alt="Triman"
      />
      <div className="text">
        {content.split("+").map((word, index) => {
          if (index === 0) {
            return <p>{word}</p>;
          }

          return (
            <p className="d-flex">
              <svg
                className="plus-text"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 5.3125H5.3125V8.50521H3.1875V5.3125H0V3.1875H3.1875V0H5.3125V3.1875H8.5"
                  fill="#1C1C1B"
                />
              </svg>
              <span>{word}</span>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default IndividualTriman;
