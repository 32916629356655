import React from "react";
import Builder, {BuilderComponent} from "../../Builder";
import classNames from "classnames";

export type Props = {
  data: {
    image?: string;
    label: string;
    text?: string;
    arrow?: BuilderComponent[];
    additionalClassNames?: Array<String>;
    imageClasses?: Array<String>;
    labelClasses?: Array<String>;
    textClasses?: Array<String>;
    arrowClasses?: Array<String>;
  }
};

const ProgressBar = ({ data }: Props) => {
  const {
    image,
    label,
    text,
    arrow,
    additionalClassNames,
    imageClasses,
    labelClasses,
    textClasses,
    arrowClasses,
  } = data;
  return (
    <div className={`spa-progress-bar ${classNames(additionalClassNames)}`}>
      <div className={'spa-progress-bar-content'}>
        {image && (
          <div className={`spa-progress-bar-image ${classNames(imageClasses)}`}>
            <img src={image} alt={`${label} icon`} />
          </div>
        )}
        <p className={`spa-progress-bar-label ${classNames(labelClasses)}`}>
          {label}
        </p>
        {text && (
          <p className={`spa-progress-bar-text ${classNames(textClasses)}`}>
            {text}
          </p>
        )}
      </div>
      {arrow && (
        <div className={`spa-progress-bar-arrow ${classNames(arrowClasses)}`}>
          <Builder children={arrow} />
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
