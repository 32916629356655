import React from "react";
import classNames from "classnames";

export type Props = {
  /**
   * Object with all content for the button
   */
  data: {
    text: string;
    cbId?: string;
    icon?: string;
    additionalClassNames?: Array<String>;
    iconClasses?: Array<String>;
    textClasses?: Array<String>;
  };
  /**
   * Object containing onClick handler function or specific data for the current button
   */
  customProps?: object;
};

const Button = ({ data, customProps }: Props) => {
  const {
    text,
    cbId,
    additionalClassNames,
    icon,
    iconClasses,
    textClasses
  } = data;
  return (
    <button className={`spa-button ${classNames(additionalClassNames)}`} onClick={cbId && customProps[cbId]}>
      <div className={'button-wrapper'}>
        <span className={classNames(iconClasses)}>
            {icon && <img src={icon} alt="button icon" />}
        </span>
        <span className={classNames(textClasses)}> {text} </span>
      </div>
    </button>
  );
};

export default Button;
