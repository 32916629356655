import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BusinessProduct from "./pages/business-product";
import { SchemaContext } from "../packs/schema-context";
import ScrollToTop from "./components/ScrollToTop";

export default function AppRoutes() {
  return (
    <Router>
      <div>
        {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <ScrollToTop>
          <Routes>
            <Route path={"/koota/:ean/:lot/*"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'meo'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/koota/:ean"} element ={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'meo'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/tracabilite_agricole_mais/:ean/:lot/*"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'adhmais'}/>
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/tracabilite_agricole_mais/:ean"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'adhmais'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/tracabilite_agricole_soja/:ean/:lot/*"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'adhsoja'}/>
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/tracabilite_agricole_soja/:ean"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'adhsoja'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/bonjour_francois/:ean/:lot/*"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'bf'}/>
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/bonjour_francois/:ean"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'bf'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/prod-agec/:product/:ean/:lot/*"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'agec'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/prod-agec/:product/:ean"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'agec'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/carbonimpact/:product/:ean/:lot/*"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'noah'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/carbonimpact/:product/:ean"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'noah'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/demo/:ean/:lot/*"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'cockpit'}/>
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/demo/:ean"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'cockpit'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/demo_textile/:ean/:lot/*"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'cockpit2'}/>
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/demo_textile/:ean"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} customBusiness={'cockpit2'} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/:business/:product/homepage"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/:business/:product/:ean/:lot/*"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} />
                )}
              </SchemaContext.Consumer>
            } />
            <Route path={"/:business/:product/:ean"} element={
              <SchemaContext.Consumer>
                {({ schema, setSchema }) => (
                  <BusinessProduct setSchema={setSchema} schema={schema} />
                )}
              </SchemaContext.Consumer>
            } />
          </Routes>
        </ScrollToTop>
      </div>
    </Router>
  );
}
