import React from "react";
import classNames from "classnames";
import { RepairableBlock, DurabilityBlock } from "./components";

type Props = {
  data: {
    type: "durability" | "repairable";
    value: number;
    additionalClassNames?: string[];
  };
};

const COLORS = {
  repairable: {
    ideal: "#2EA24E",
    normal: "#90CB46",
    warning: "#FFC43F",
    danger: "#FF7432",
    critical: "#F32F2A",
  },
  durability: {
    ideal: "#148C45",
    normal: "#FFB727",
    warning: "#F46030",
    danger: "#D02744",
    critical: "#922A41",
  },
};

const DurabilityRepairableIndicator = ({ data }: Props) => {
  const { type, value, additionalClassNames } = data;

  const roundToFirstDecimal = (value) => {
    if (Number.isInteger(value)) {
      return value;
    }

    return Math.round(value * 10) / 10;
  };

  const getLevel = (value) => {
    switch (true) {
      case value >= 0 && value <= 1.9:
        return "critical";
      case value >= 2 && value <= 3.9:
        return "danger";
      case value >= 4 && value <= 5.9:
        return "warning";
      case value >= 6 && value <= 7.9:
        return "normal";
      case value >= 8 && value <= 10:
        return "ideal";
      default:
        return null;
    }
  };

  const colorScheme = COLORS[type];
  const level = getLevel(roundToFirstDecimal(value));

  if (!type) {
    return null;
  }

  return (
    <div className={`${classNames(additionalClassNames)}`}>
      {type === "repairable" && (
        <RepairableBlock
          color={colorScheme[level]}
          value={roundToFirstDecimal(value)}
        />
      )}
      {type === "durability" && (
        <DurabilityBlock
          color={colorScheme[level]}
          value={roundToFirstDecimal(value)}
          level={level}
        />
      )}
    </div>
  );
};

export default DurabilityRepairableIndicator;
