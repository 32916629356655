import React, {useEffect, useState} from "react";
import Builder, { BuilderComponent } from "../../Builder";
import classNames from "classnames";

export type Props = {
  data: {
    additionalClassNames: Array<String>;
    title: BuilderComponent[];
    titleClasses?: Array<String>;
    content: BuilderComponent[];
    contentClasses?: Array<String>;
    footer?: boolean;
    isOptional?: {
      test: any;
    }
  }
};

const Accordion = ({ data }: Props) => {
  const {
    title,
    titleClasses,
    additionalClassNames,
    content,
    contentClasses,
    footer,
    isOptional,
  } = data;

  const [isActive, setIsActive] = useState(false);

  if (isOptional && (!isOptional?.test || isOptional?.test?.length === 0)) {
    return null;
  }

  useEffect(() => {
    let scroll;
    if(isActive && footer) {
      scroll = setTimeout(()=> {
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }, 100)
    }

    return () => clearTimeout(scroll);
  }, [isActive]);

  return (
    <div className={`spa-accordion ${classNames(additionalClassNames)}`}>
      <div
        className={`spa-accordion-title ${classNames(titleClasses)} ${isActive ? 'open' : ''}`}
        onClick={() => setIsActive(!isActive)}
      >
        <Builder children={title} />
      </div>
      <div className={`spa-accordion-content ${classNames(contentClasses)} ${isActive ? 'open' : ''}`}>
        <Builder children={content} />
      </div>
    </div>
  );
};

export default Accordion;
