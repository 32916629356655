import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Builder, { BuilderComponent } from "../../Builder";
import { replaceDoubleSlashWithSingleSlash, getAbsolutePath } from "../../helpers";

export type Props = {
  data: {
    path: string;
    children: BuilderComponent[];
    isAbsolute?: boolean;
    isOptional?: {
      test: string;
    };
  }
};

const LinkContainer = ({ data }: Props) => {
  const { path, children, isAbsolute } = data;
  const location = useLocation();

  if (data.isOptional && (!data.isOptional?.test || data.isOptional?.test.length === 0)) {
    return null;
  }

  return (
    <NavLink
      key={`${path}_${Math.random()}`}
      to={isAbsolute? getAbsolutePath(location.pathname, data?.path) : replaceDoubleSlashWithSingleSlash(location.pathname, data?.path)}
      className={`spa-link-container`}
    >
      {children?.map((child, index) => (
        <Builder key={`${index}_${Math.random()}`} schema={child} />
      ))}
    </NavLink>
  );
};

export default LinkContainer;
