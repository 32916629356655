// Components
import TraceabilityHeader from "./components/TraceabilityHeader";
import SectionText from "./components/SectionText/";
import SectionSeparatedText from "./components/SectionSeparatedText";
import Image from "./components/Image";
import Button from "./components/Button";
import NavBar from "./components/NavBar";
import YouTube from "./components/YouTube";
import Link from "./components/Link";
import Legend from "./components/Legend";
import LocationCard from "./components/LocationCard";
import BackToForm from "./components/BackToForm";
import ScrollToTop from "./components/ScrollToTop";
import Statistic from "./components/Statistic";
import LanguageSelector from "./components/LanguageSelector";
import Loader from "./components/Loader";
import Map from "./components/Map";
import ProgressRow from "./components/ProgressRow";
import CustomPackageTriman from "./components/CustomPackageTriman";
import EcobalyseImpactIndicator from "./components/EcobalyseImpactIndicator";

// Containers
import SimpleContainer from "./containers/SimpleContainer";
import Form from "./containers/Form";
import LinkContainer from "./containers/LinkContainer";
import Card from "./containers/Card";
import ModalContainer from "./containers/ModalContainer";
import Accordion from "./containers/Accordion";
import ListItem from "./containers/ListItem";
import ProgressBar from "./containers/ProgressBar";
import Catalog from "./containers/Catalog";
import CustomRoutes from "./CustomRoutes";
import DurabilityRepairableIndicator from "./containers/DurabilityRepairableIndicator";

export default {
  Form,
  SimpleContainer,
  TraceabilityHeader,
  LinkContainer,
  SectionText,
  Image,
  NavBar,
  Card,
  Button,
  Map,
  ModalContainer,
  YouTube,
  Legend,
  ProgressBar,
  ProgressRow,
  Accordion,
  SectionSeparatedText,
  LocationCard,
  ListItem,
  Link,
  Statistic,
  BackToForm,
  ScrollToTop,
  LanguageSelector,
  Catalog,
  Loader,
  CustomRoutes,
  DurabilityRepairableIndicator,
  CustomPackageTriman,
  EcobalyseImpactIndicator,
};
