import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Select, { components } from "react-select";
import Flags from "country-flag-icons/react/3x2";
import { SchemaContext} from "../../../packs/schema-context";
import classNames from "classnames";

export type Props = {
  customBusiness?: string;
  customProps?: {
    ean: string;
    lot: string;
    business: string;
    product: string;
  };
  data: {
    lang: string;
    langList: Array<{
      code: string;
      label: string;
      country_code: string;
    }>;
    displayTextInOption: boolean;
    displayFlagInOption?: boolean;
    displayTextInSelectedValue?: boolean;
    displayFlagInSelectedValue?: boolean;
    additionalClassNames?: Array<String> | undefined;
    selectedOptionColor?: string;
  };
};

const LanguageSelector = ({
  data,
  customBusiness,
  customProps,
}: Props) => {
  const { setSchema } = useContext(SchemaContext);
  const {
    langList,
    lang,
    additionalClassNames,
    displayFlagInOption,
    displayTextInOption = true,
    displayFlagInSelectedValue,
    displayTextInSelectedValue = true,
    selectedOptionColor,
  } = data;
  const { business, product, ean, lot } = useParams();

  const fetchNewSchema = async (lang) => {
    const fetchUrl = lot || customProps?.lot
      ? `/api/schemas/${customBusiness || business || customProps.business}/${ean || customProps?.ean}/${lot || customProps?.lot}?lang=${lang}`
      : ean
        ? `/api/schemas/${customBusiness || business || customProps.business}/${ean || customProps?.ean}?lang=${lang}`
        : `/api/schemas/${customBusiness || business || customProps.business}/${product || customProps?.product}/homepage?lang=${lang}`;

    const response = await fetch(fetchUrl,
      {
        method: 'GET',
      }
    );
    return await response.json();
  }

  const onSelectLanguage = (selectedOption) => {
    if(selectedOption?.code === lang) {
      return null;
    }

    try {
      fetchNewSchema(selectedOption?.code)
        .then(({ root }) => {
          if (root) {
            setSchema(root);
          }
        });
    } catch (e) {
      console.log('err', e);
    }
  };

  const { Option, SingleValue, MenuList, Control } = components;
  const CustomControl = ({ children, ...props }) => (
    <Control {...props} className={'language-selector-control'}>
      {children}
    </Control>
  );
  const CustomOption = (props) => {
    const Flag = displayFlagInOption && Flags[props?.data?.country_code];
    return (
      <Option {...props}>
        {displayFlagInOption && (
          <Flag className={'flag-item'} />
        )}
        {displayTextInOption && props?.data?.label}
      </Option>
    );
  };
  const SelectedLanguage = ({ children, ...props}) => {
    const Flag = displayFlagInSelectedValue && Flags[props?.data?.country_code];
    return (
      <SingleValue {...props} className={'language-selector-selected'}>
        {displayFlagInSelectedValue && (
          <Flag className={'flag-item'}/>
        )}
        {displayTextInSelectedValue && children}
      </SingleValue>
    );
  };
  const CustomMenuList = (props) => {
    return (
      <MenuList {...props} className={`menu-list ${!displayTextInOption ? 'only-flag' : ''}`}/>
    )
  }

  const colourStyles = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (styles) => ({
      ...styles,
      border: 'none',
      boxShadow: 'none',
    }),
    option: (styles, { data, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? selectedOptionColor || "rgb(38, 132, 255)" : "transparent",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        boxShadow: '0 0 0 0 rgba(51, 51, 51, 10%), 0 4px 11px rgba(51, 51, 51, 20%)',
        width: !displayTextInSelectedValue || !displayTextInOption ? 'auto' : '100%',
      }
    },
  };

  return (
    <div className={'language-selector-wrapper'}>
      <Select
        id='language_selector'
        className={`language-selector ${classNames(additionalClassNames)} ${!displayTextInSelectedValue ? 'only-flag' : ''}`}
        value={langList?.filter((item) => item?.code === lang)}
        onChange={onSelectLanguage}
        options={langList}
        getOptionValue={option => {
          return option.code
        }}
        components={{
          Control: CustomControl,
          Option: CustomOption,
          SingleValue: SelectedLanguage,
          MenuList: CustomMenuList,
        }}
        isSearchable={false}
        styles={colourStyles}
        // defaultMenuIsOpen={true}
      />
    </div>
  );
};

export default LanguageSelector;