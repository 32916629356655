const replaceDoubleSlashWithSingleSlash = (url: String, path: String) =>
  `${url}/${path}`.replace(`//`, `/`);

const getAbsolutePath = (url: string, path: string) => {
  if(!url) {
    return '';
  }

  const absolute_url = url.slice(0, url.lastIndexOf('/'));
  return replaceDoubleSlashWithSingleSlash(absolute_url, path);
}

const checkImgExist = (url) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(true);
    img.onerror = reject;
    img.src = url;
  })
};

const findSpecificIndex = (string, symbol, specificCount) => {
  let counter = 0;
  for (let i = 0; i < string.length; i++) {
    if (string[i] === symbol && ++counter == specificCount) {
      return i
    }
  }
  return undefined;
};

export {
  replaceDoubleSlashWithSingleSlash,
  getAbsolutePath,
  checkImgExist,
  findSpecificIndex
};
