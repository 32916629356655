import React from "react";
import views from "./views";

export type BuilderComponent = {
  name: string;
  data: object;
};

export type Props = {
  schema?: BuilderComponent;
  children?: BuilderComponent[];
  setSchema?: Function;
  customProps?: object;
  customBusiness?: string;
};

const Builder = ({
  schema,
  children,
  setSchema,
  customProps,
  customBusiness,
}: Props) => {
  try {
    if (children) {
      return (
        <>
          {children?.map(({ name, data }, index) =>
            views[name]({
              data,
              setSchema: setSchema,
              customProps,
              customBusiness,
            })
          )}
        </>
      );
    }
    return views[schema?.name]({
      data: schema?.data,
      setSchema: setSchema,
      customProps,
      customBusiness,
    });
  } catch (e) {
    console.log('Error:', e)
    return <div>oops</div>;
  }
};

export default Builder;
