import React from "react";

interface Props {
  color: string;
  value: string;
}

const RepairableBlock = ({ color, value }: Props) => {
  return (
    <div>
      <div
        className={"repairable-block-container"}
        style={{ border: `1px solid ${color}` }}
      >
        <div
          className={"repairable-block-icon"}
          style={{ backgroundColor: color }}
        >
          <svg
            viewBox="0 0 59 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.071 43.242C53.324 45.494 53.324 49.149 51.072 51.402C48.818 53.654 45.164 53.654 42.91 51.402L31.712 40.202C28.22 40.983 24.41 40.019 21.698 37.306C18.569 34.177 17.901 29.75 19.107 26.192C19.215 25.88 19.681 25.855 19.847 26.021C19.847 26.021 26.322 32.498 26.325 32.499C27.2 33.374 28.626 33.376 29.502 32.5L32.18 29.822C33.054 28.946 33.053 27.52 32.183 26.644L25.704 20.172C25.539 20.007 25.562 19.539 25.876 19.432C29.434 18.233 33.848 18.9 36.976 22.03C39.689 24.741 40.653 28.549 39.872 32.041L51.071 43.242ZM57.308 24.639L52.028 23.814C51.452 21.407 50.506 19.145 49.248 17.091L52.523 12.603C52.936 12.038 52.868 11.173 52.374 10.679L47.883 6.18903C47.389 5.69403 46.524 5.62804 45.96 6.03904L41.471 9.31403C39.417 8.05803 37.155 7.11003 34.749 6.53403L33.924 1.25504C33.814 0.565035 33.155 3.43323e-05 32.457 3.43323e-05H26.106C25.408 3.43323e-05 24.749 0.565035 24.64 1.25504L23.815 6.53403C21.407 7.11003 19.146 8.05803 17.091 9.31403L12.604 6.03904C12.039 5.62804 11.174 5.69403 10.68 6.18903L6.189 10.679C5.695 11.173 5.627 12.039 6.04 12.603L9.316 17.091C8.059 19.145 7.111 21.407 6.534 23.815L1.257 24.639C0.566 24.747 0 25.407 0 26.105V32.456C0 33.154 0.566 33.814 1.257 33.922L6.534 34.747C7.111 37.153 8.059 39.417 9.316 41.47L6.04 45.958C5.627 46.522 5.695 47.389 6.189 47.882L10.68 52.373C11.174 52.866 12.039 52.933 12.604 52.521L17.091 49.246C19.146 50.503 21.407 51.451 23.816 52.027L24.639 57.306C24.749 57.996 25.408 58.561 26.106 58.561H32.457C33.155 58.561 33.814 57.996 33.924 57.306L34.748 52.027C35.571 51.83 36.376 51.589 37.163 51.307L32.109 46.253C31.187 46.406 30.247 46.505 29.281 46.505C19.769 46.505 12.057 38.793 12.057 29.281C12.057 19.768 19.769 12.056 29.281 12.056C38.794 12.056 46.506 19.768 46.506 29.281C46.506 30.445 46.387 31.582 46.166 32.682L51.13 37.645C51.489 36.707 51.792 35.739 52.028 34.747L57.307 33.922C57.998 33.814 58.562 33.155 58.562 32.456V26.105C58.563 25.406 57.999 24.747 57.308 24.639Z"
              fill="white"
            />
          </svg>
        </div>
        <div className={"repairable-block-content"}>
          <p className={"value"}>{value}</p>
          <p className={"total"}>/ 10</p>
        </div>
      </div>
      <p className="repairable-block-footnote">INDICE DE RÉPARABILITÉ</p>
    </div>
  );
};

export default RepairableBlock;
