import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { SchemaContext } from "../../../packs/schema-context";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import SectionText from "../../components/SectionText/SectionText";
import Builder, { BuilderComponent } from "../../Builder";
import Loader from "../../components/Loader";
import Selectors from "./components/Selectors";
import { findSpecificIndex } from "../../helpers";

export type Props = {
  data: {
    callback: string;
    inputDescription?: string;
    question?: BuilderComponent;
    inputLabel?: string;
    inputRequired?: boolean;
    commonErrorMessage: string;
    submitButtonText: string;
    ageChecker?: boolean;
    ageErrorMessage?: string;
    ageQuestion?: string;
    ageNoBtn?: string;
    ageYesBtn?: string;
    defaultValues?: {
      lot?: string | number;
      color?: string;
    };
    loaderInBtn?: boolean;
    inputPlaceholder?: string;
    lotimage?: BuilderComponent;
    dynamicSelectors?: boolean;
    products?: any;
    radioLabel?: string;
    radioFieldName?: string;
    radioOrder?: string;
    selectLabel?: string;
    selectFieldName?: string;
    productEanField?: string;
    productTitleField?: string;
    productImageField?: string;
    selectorsInfo?: string;
  }
};

const Form = ({ data }: Props) => {
  const {
    callback,
    inputDescription,
    question,
    inputLabel,
    inputRequired = true,
    commonErrorMessage,
    submitButtonText,
    ageChecker,
    ageErrorMessage,
    ageQuestion,
    ageNoBtn,
    ageYesBtn,
    defaultValues,
    loaderInBtn,
    inputPlaceholder = null,
    lotimage,
    dynamicSelectors,
    products,
    radioLabel,
    radioFieldName,
    radioOrder,
    selectLabel,
    selectFieldName,
    productEanField,
    productTitleField,
    productImageField,
    selectorsInfo,
  } = data;

  const { setSchema } = useContext(SchemaContext);
  const [error, setError] = useState<boolean>(false);
  const [selectedRadioValue, setSelectedRadioValue] = useState<string | null>(
    sessionStorage.getItem('color') || defaultValues?.color || null
  );
  const [selectedOptionValue, setSelectedOptionValue] = useState<number | null>(
    +sessionStorage.getItem('year') || null
  );
  const [filteredProduct, setFilteredProduct] = useState<{ [key: string]: string }>(null);
  const { ean, product } = useParams();
  const navigate = useNavigate();
  let { pathname } = useLocation();

  const radioValues: string[] | undefined = products && [
    ...new Set(products
      .filter((object) => !!object[radioFieldName])
      .map((object) => object[radioFieldName])
      .sort((a,b) => {
        const order = radioOrder?.split(', ');
        if(Array.isArray(order) && order.length > 0) {
          let i1 = order.indexOf(a)
          let i2 = order.indexOf(b);
          return i1 < 0 ? 1 : i2 < 0 ? -1 : i1 - i2;
        } else {
          // sort as strings
          return a > b ? 1 : -1;
        }
      })
    )
  ]

  const selectValues: number[] | undefined =
    products &&
    products
      .filter((item: object) => item[radioFieldName] === selectedRadioValue)
      .map((item: object) => item[selectFieldName])
      .sort((a, b) => b - a);
  const uniqueSelectValues = [...new Set(selectValues)];

  const { register, handleSubmit, formState, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      ...(radioValues && {
          color: radioValues?.includes(selectedRadioValue)
            ? selectedRadioValue
            : radioValues[0]
        }
      ),
      ...(uniqueSelectValues && {
        year: uniqueSelectValues?.includes(selectedOptionValue)
          ? selectedOptionValue
          : uniqueSelectValues[0]
      })
    },
  });

  useEffect(() => {
    if (radioValues && !radioValues.includes(selectedRadioValue)) {
      setSelectedRadioValue(radioValues[0]);
    }
  }, [])

  useEffect(() => {
    if (Array.isArray(uniqueSelectValues) && uniqueSelectValues.length > 0) {
      const value = uniqueSelectValues.includes(selectedOptionValue) ? selectedOptionValue : uniqueSelectValues[0];
      setSelectedOptionValue(value);
    }
  }, [selectedRadioValue])

  useEffect(() => {
    if (selectedRadioValue && selectedOptionValue) {
      const filteredProduct = getProduct();

      if (filteredProduct) {
        sessionStorage.setItem('year', `${selectedOptionValue}`);
        sessionStorage.setItem('color', selectedRadioValue);

        setFilteredProduct(filteredProduct);
      }
    }
  }, [selectedRadioValue, selectedOptionValue])

  const getProduct = () => {
    return products.filter(
      (item) =>
        item[radioFieldName] === selectedRadioValue &&
        item[selectFieldName] === +selectedOptionValue
    )[0];
  };

  const onSubmit = async (form) => {
    try {
      const fetchUrl = ean
        ? `${callback}/${ean}/${form.lot}`
        : `${callback}/${filteredProduct[productEanField]}/${form.lot}`;

      const { root } = await (
        await fetch(fetchUrl, {
          method: "POST",
          body: form,
        })
      ).json();
      setError(false);
      setSchema(root);

      if (!ean) {
        const index = findSpecificIndex(pathname, '/', 2);
        const path = `${pathname.slice(0, index)}${
          product ? '/' + product : ''
        }/${filteredProduct[productEanField]}/${form.lot}`;

        navigate(path);
      } else {
        navigate(form.lot);
      }

    } catch (e) {
      setError(true);
    }
  };

  const watchList = watch(["age"]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`form-container`}>
      <div className={`text-center form-input-wrapper`} style={{ position: "relative" }}>
        {dynamicSelectors && (
          <Selectors
            product={filteredProduct}
            imageField={productImageField}
            titleField={productTitleField}
            info={selectorsInfo}
            register={register}
            selectLabel={selectLabel}
            setSelectValue={setSelectedOptionValue}
            selectValues={uniqueSelectValues}
            radioLabel={radioLabel}
            radioValues={radioValues}
            selectedRadioValue={selectedRadioValue}
            setRadioValue={setSelectedRadioValue}
          />
        )}
        {question && (
          <Builder children={[question]} />
        )}
        <input
          {...register("lot", { required: inputRequired })}
          className={`spa-form-input`}
          placeholder={inputPlaceholder}
          disabled={formState.isSubmitting}
        />
        <SectionText
          data={{
            text: inputLabel,
            additionalClassNames: [
              "section-text",
              "text-center",
              "spa-lot-label",
            ],
          }}
        />
        {inputDescription && (
          <SectionText
            data={{
              text: inputDescription,
              additionalClassNames: [
                "section-text",
                "spa-lot-description",
              ],
            }}
          />
        )}
      </div>
      {lotimage && (
        <Builder children={[lotimage]} />
      )}
      {ageChecker && (
        <div className={"form-age-checker-container"}>
          <SectionText
            data={{
              text: ageQuestion,
              additionalClassNames: [
                "spa-form-age-question",
                "section-text",
                "text-center",
              ],
            }}
          />
          <section className={`form-container-labels`}>
            <div>
              <input
                {...register("age", { required: true })}
                type="radio"
                value="oui"
                id={"oui"}
              />
              <label
                htmlFor="oui"
                className={
                  watchList[0] === "oui"
                    ? `spa-form-label-selected`
                    : `spa-form-label`
                }
              >
                { ageYesBtn }
              </label>
            </div>
            <div>
              <input
                {...register("age", { required: true })}
                type="radio"
                value="non"
                id={"non"}
              />
              <label
                htmlFor="non"
                className={`spa-form-label ${watchList[0] === "oui" ? 'not-selected' : ''}`}
              >
                { ageNoBtn }
              </label>
            </div>
          </section>
        </div>
      )}
      <div className={`spa-form-error`}>
        {!formState.isSubmitting && watchList[0] === "non" && (
          <span>{ ageErrorMessage }</span>
        )}
        {error && watchList[0] !== "non" && (
          <span>{ commonErrorMessage }</span>
        )}
      </div>
      <div style={{ textAlign: "center" }}>
        <button
          type="submit"
          disabled={!formState.isValid || (ageChecker && watchList[0] !== "oui") || formState.isSubmitting}
          className={`spa-form-submit-btn ${formState.isSubmitting ? 'spa-form-submit-btn-submitting' : ''}`}
        >
          {loaderInBtn && formState.isSubmitting? (
            <div className={'button-loader'}>
              <Loader />
            </div>
          ) : submitButtonText}
        </button>
      </div>
    </form>
  );
};

export default Form;
