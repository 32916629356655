import React, { useState } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import Builder, { BuilderComponent } from "../../Builder";

export type Props = {
  data: {
    trigger: BuilderComponent;
    children: BuilderComponent[];
    cbId: string;
    isOptional?: object;
    additionalClassNames?: string[];
  };
};

const ModalContainer = ({ data }: Props) => {
  const { trigger, children, cbId, additionalClassNames } = data;

  const [isOpen, setIsOpen] = useState<Boolean>(false);

  if (data.isOptional && (!data.isOptional?.test || data.isOptional?.test.length === 0)) {
      return null;
  }

  return (
    <>
      <div className={`modal-block ${classNames(additionalClassNames)}`} onClick={() => setIsOpen(true)}>
        <Builder schema={trigger} />
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        style={{
          overlay: { zIndex: 1001 },
          content: {
            padding: 0,
            inset: "unset",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            overflowY: "scroll",
            maxHeight: "90%",
          },
        }}
      >
        <Builder
          children={children}
          customProps={{ [cbId]: () => setIsOpen(false) }}
        />
      </Modal>
    </>
  );
};

export default ModalContainer;
