import React from "react";
import classNames from "classnames";

export type Props = {
  data: {
    src?: string;
    additionalClassNames?: Array<String>;
    backgroundImage?: string;
    placeholderImage?: string;
  };
};

const Image = ({ data }: Props) => {
  const { src, additionalClassNames, backgroundImage, placeholderImage } = data;
  const displayPlaceholder = src && src?.length > 0 ? false : true;

  if (backgroundImage) {
    return (
      <div
        style={{ backgroundImage: `url("${backgroundImage}")` }}
        className={classNames(additionalClassNames)}
      />
    );
  }

  const image = (
    <img
      src={!displayPlaceholder ? src : placeholderImage}
      className={`${classNames(additionalClassNames)} ${
        displayPlaceholder ? "placeholder" : ""
      }`}
      alt="image"
    />
  );

  return src ? (
    image
  ) : placeholderImage ? (
    <div className="placeholder-wrapper">{image}</div>
  ) : null;
};

export default Image;
