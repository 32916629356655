import React from "react";
import Builder, { BuilderComponent } from "../../Builder";
import classNames from "classnames";

export type Props = {
  data: {
    icon: string;
    children: BuilderComponent[];
    additionalClassNames?: Array<String>;
    iconClasses?: Array<String>;
    childrenClasses?: Array<String>;
    isOptional?: {
      test: any;
    }
  }
}

const ListItem = ({ data }: Props) => {
  const {
    icon,
    children,
    additionalClassNames,
    iconClasses,
    childrenClasses,
  } = data;

  if (data.isOptional && (!data.isOptional?.test || data.isOptional?.test.length === 0)) {
    return null;
  }

  return (
    <div className={`spa-list-item ${classNames(additionalClassNames)}`}>
      {icon && (
        <img
          src={icon}
          alt="list icon"
          className={`spa-list-item-icon ${classNames(iconClasses)}`}
        />
      )}
      <div className={`spa-list-item-children ${classNames(childrenClasses)}`}>
        <Builder children={children} />
      </div>
    </div>
  );
};

export default ListItem;
