import React from "react";
import { DOTS, usePaginationRange } from "./usePaginationRange";

type Props = {
  currentPage: Number;
  onChange: Function;
  totalPageCount: Number;
  buttonConst: Number;
  siblingCount: Number;
  color?: string;
  activeBgColor?: string;
}

const Pagination = ({
  currentPage,
  onChange,
  totalPageCount,
  buttonConst,
  siblingCount,
  color = '#000',
  activeBgColor,
}: Props) => {
  const paginationRange = usePaginationRange({
    totalPageCount,
    buttonConst,
    siblingCount,
    currentPage,
  });

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    window.history.replaceState({ usr: { ...window.history.state?.usr, page: pageNumber }}, document.title)
    onChange(pageNumber);
  }

  return (
    <div className={'pagination-wrapper'}>
      <button
        onClick={() => onChange((page) => page - 1)}
        className={`pagination-prev-item ${currentPage === 1 ? 'disabled' : ''}`}
        disabled={currentPage === 1}
      >
        <svg
          height="24px"
          width="24px"
          id="Layer_1"
          enableBackground="new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          fill={color}
        >
          <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "/>
        </svg>
      </button>
      {paginationRange.map((item, index) => {
        if (item === DOTS) {
          return (
            <button
              key={index}
              className={`pagination-item dots`}
              disabled={true}
              style={{color}}
            >
              &#8230;
            </button>
          );
        }
        return (
          <button
            key={index}
            onClick={changePage}
            className={`pagination-item ${
              currentPage === item ? 'active' : ''
            }`}
            style={{
              color,
              backgroundColor: currentPage === item ? activeBgColor : null
            }}
          >
            <span>{item}</span>
          </button>
        );
      })}
      <button
        onClick={() => onChange((page) => page + 1)}
        className={`pagination-next-item ${currentPage === totalPageCount ? 'disabled' : ''}`}
        disabled={currentPage === totalPageCount}
      >
        <svg
          height="24px"
          width="24px"
          id="Layer_1"
          enableBackground="new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          fill={color}
        >
          <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 "/>
        </svg>
      </button>
    </div>
  )
};

export default Pagination;