import React from "react";
import classNames from "classnames";

export type Props = {
  data: {
    percent: string | number;
    rowColor: string;
    trailColor?: string;
    showInfo?: boolean;
    additionalClassNames?: Array<String>;
    percentClasses?: Array<String>;
    isOptional?: {
      test: any;
    };
  };
};

const ProgressRow = ({data}: Props) => {
  const {
    percent = 0,
    rowColor,
    trailColor,
    showInfo = false,
    additionalClassNames,
    percentClasses,
    isOptional,
  } = data;

  if (isOptional && (!isOptional?.test || isOptional?.test?.length === 0)) {
    return null;
  }

  const checkPercentValue = (value) => {
    if (typeof value === 'string') {
      return value
        .replace(",", ".")
        .replace("-", "");
    }
    return value;
  };

  return (
    <div className={`spa-progress-row ${classNames(additionalClassNames)}`}>
      <div className={'spa-progress-row-bar'} style={{ backgroundColor: trailColor }}>
        <div style={{ width: `${checkPercentValue(percent)}%`, backgroundColor: rowColor }}></div>
      </div>
      {showInfo && (
        <p className={`spa-progress-row-value ${classNames(percentClasses)}`}>
          {`${percent}%`}
        </p>
      )}
    </div>
  )
};

export default ProgressRow;