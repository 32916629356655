import React from "react";

export type Props = {
  data: object;
};

const Legend = ({ data }: Props) => {
  return (
    <div className={`legend-root`}>
      {Object.keys(data).map((key) => (
        <div key={key} className={`legend-root-item`}>
          <img src={data[key]?.icon} alt="" className={`legend-root-icon`} />
          <span className={`legend-root-text`}>{key.toUpperCase()}</span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
