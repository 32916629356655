import React, { useContext, useState } from "react";
import { SchemaContext } from "../../packs/schema-context";
import { useNavigate, useLocation } from "react-router-dom";
import { findSpecificIndex } from "../helpers";
import classNames from "classnames";

const BackToForm = ({ data, customProps }) => {
  const { setSchema } = useContext(SchemaContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { product, ean } = customProps;

  const currentPathname = location.pathname;
  const index = findSpecificIndex(currentPathname, '/', 2);
  const formPagePath = `${currentPathname.slice(0, index)}${product ? '/' + product : ''}/${ean}`;

  const { text, image, additionalClassNames, textClasses, imageClasses } = data;

  const [error, setError] = useState(false);

  const onClick = async () => {
    try {
      setSchema(null);
      navigate(formPagePath, { state: { ...window.history.state?.usr, isFetched: false }});
    } catch (e) {
      setError(true);
      console.log('err', e);
    }
  };

  if(!customProps?.ean) {
    return null;
  }

  return (
    <button onClick={onClick} className={`back-button ${classNames(additionalClassNames)}`}>
      {text && <span className={classNames(textClasses)}> {text} </span>}
      {image && <img src={image} className={classNames(imageClasses)} alt="back"/>}
    </button>
  );
};

export default BackToForm;
