import React from "react";
import classNames from "classnames";

export type Props = {
  data: {
    value: number | string;
    total: number | string;
    title?: string;
    icon?: string;
    additionalClassNames?: Array<String>;
    titleClasses?: Array<String>;
    valuesClasses?: Array<String>;
    iconClasses?: Array<String>;
  };
};

const Statistic = ({ data }: Props) => {
  const {
    value,
    total,
    title,
    icon,
    additionalClassNames,
    titleClasses,
    valuesClasses,
    iconClasses
  } = data;

  return (
    <div className={`spa-statistic ${classNames(additionalClassNames)}`}>
      {icon &&
        <img src={icon} alt='Statistic Icon' className={`spa-statistic-icon ${classNames(iconClasses)}`} />
      }
      <div className={'spa-statistic-content'}>
        <p className={`spa-statistic-values ${classNames(valuesClasses)}`}>
          <span>{value}</span>/<span>{total}</span>
        </p>
        {title &&
          <p className={`spa-statistic-title ${classNames(titleClasses)}`}>
            {title}
          </p>
        }
      </div>
    </div>
  )
};

export default Statistic;