import React from "react";

interface Props {
  bac: string;
  glass: string;
}

const DoubleTriman = ({ bac, glass }: Props) => {
  return (
    <div className="double-triman">
      <img
        src="/images/restitution/agec/triman/bacdetri-triverre.svg"
        className="double-triman-image"
        alt=""
      />
      <div className="bac-text">
        {bac.split("+").map((word, index) => {
          if (index === 0) {
            return <p>{word}</p>;
          }

          return (
            <p className="d-flex">
              <svg
                className="plus-text"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 5.3125H5.3125V8.50521H3.1875V5.3125H0V3.1875H3.1875V0H5.3125V3.1875H8.5"
                  fill="#1C1C1B"
                />
              </svg>
              <span>{word}</span>
            </p>
          );
        })}
      </div>
      <div className="glass-text">
        {glass.split("+").map((word, index) => {
          if (index === 0) {
            return <p>{word}</p>;
          }

          return (
            <p className="d-flex">
              <svg
                className="plus-text"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 5.3125H5.3125V8.50521H3.1875V5.3125H0V3.1875H3.1875V0H5.3125V3.1875H8.5"
                  fill="#1C1C1B"
                />
              </svg>
              <span>{word}</span>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default DoubleTriman;
