import React from "react";
import classNames from "classnames";
import { IndividualTriman, DoubleTriman } from "./components";

type Props = {
  data: {
    bac?: string;
    glass?: string;
    src: string;
    footnote?: string;
    additionalClassNames?: string[];
  };
};

const CustomPackageTriman = ({ data }: Props) => {
  const { bac, glass, src, footnote, additionalClassNames } = data;

  if (!bac && !glass) {
    return null;
  }

  return (
    <a href={src} className={classNames(additionalClassNames)} target="_blank">
      <div className="package-triman-container">
        <div className="package-triman-wrapper">
          {bac && !glass && (
            <IndividualTriman type={'bac'} content={bac} />
          )}
          {!bac && glass && (
            <IndividualTriman type={'glass'} content={glass} />
          )}
          {bac && glass && (
            <DoubleTriman bac={bac} glass={glass} />
          )}
        </div>
        {footnote && <p className="package-triman-footnote">{footnote}</p>}
      </div>
    </a>
  );
};

export default CustomPackageTriman;
