import React from "react";

export type Props = {
  data: {
    src: string,
  }
}

const YouTube = ({ data }: Props) => {
  const { src } = data;
  return (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YouTube;
